import { Link } from "gatsby";
import React from "react";
import Button from "../components/Button";
import Layout from "../components/layout";

function NotFoundPage() {
  return (
    <Layout>
      <div className="text-center my-20">
        <h2 className="font-bold my-8 p-3">Página não encontrada...</h2>
        <Link to="/">
          <Button className="my-10">Voltar para a página inicial</Button>
        </Link>
      </div>
    </Layout>
  );
}

export default NotFoundPage;
